<template>
  <v-list
    subheader
    :class="
      user.account_enabled && !user.account_locked ? 'enabled' : 'disabled'
    "
  >
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >{{ user.first_name }} {{ user.last_name }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="association in user.associations" :key="association.id">
      <v-list-item-icon>
        <v-icon>mdi-account-group</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          ><router-link
            :to="{ name: 'association', params: { id: association.id } }"
            >{{ association.name }}</router-link
          ></v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-medal</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="wrap">
          <v-chip
            small
            class="mr-2"
            v-for="role in user.roles"
            :key="role.id"
            >{{ role.name }}</v-chip
          >
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-email</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ user.email }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-flag</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ user.country }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="user.contact_phone">
      <v-list-item-icon>
        <v-icon>mdi-phone</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ user.contact_phone }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="user.digital_id">
      <v-list-item-icon>
        <v-icon>mdi-card-account-details</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >{{ user.digital_id_country }}/{{
            user.digital_id
          }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="user.pricing_plan">
          Plan: {{ user.pricing_plan.name }}
          <small
            >({{ user.pricing_plan.signatures }} signatures/month,
            {{ user.pricing_plan.disk_quota_mb }} Mb)</small
          >
        </v-list-item-title>
        <v-list-item-title v-else>
          User doesn't have a pricing plan.
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-pen</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ user.signatures }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >Joined:
          {{ new Date(user.signup_date).toLocaleString() }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="user.signoff_date">
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          >Left:
          {{ new Date(user.signoff_date).toLocaleString() }}</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon v-if="user.account_enabled">mdi-account-check</v-icon>
        <v-icon v-else>mdi-account-cancel</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          user.account_enabled ? "enabled" : "disabled"
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon v-if="user.account_locked">mdi-lock</v-icon>
        <v-icon v-else>mdi-lock-open</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{
          user.account_locked ? "locked" : "unlocked"
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "UserCard",
  props: { user: { type: Object, required: true } },
  data() {
    return {
      expandSignatures: false
    };
  }
};
</script>

<style scoped>
.v-list {
  border-left: 4px #a5d6a7 solid;
}

.wrap {
  white-space: normal;
}

.v-list.disabled {
  border-left-color: #e57373;
}
</style>
