<template>
  <v-snackbar
    :value="value"
    @input="$emit('input', $event)"
    :bottom="true"
    :color="color"
    :timeout="color == 'success' ? 2000 : 6000"
    :outlined="false"
    :top="true"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn text dark v-bind="attrs" @click="$emit('input', false)">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    text: { type: String, required: true },
    color: { type: String, default: "success" },
    value: { type: Boolean, required: true }
  }
};
</script>
