<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card flat>
        <v-card-text>
          <v-text-field
            disabled
            v-model="user.username"
            required
            name="username"
            label="Username"
          />
          <v-text-field
            v-model="user.first_name"
            required
            name="first_name"
            label="First name"
            :rules="nameRules"
          />
          <v-text-field
            v-model="user.last_name"
            required
            name="last_name"
            label="Last name"
            :rules="nameRules"
          />
          <v-text-field
            v-model="user.email"
            required
            type="email"
            name="email"
            label="E-mail"
            :rules="emailRules"
          />
          <v-switch
            disabled
            v-model="user.email_verified"
            label="Email verified"
          ></v-switch>
          <v-text-field
            v-model="user.contact_phone"
            required
            name="contact_phone"
            label="Contact Phone"
          />
          <CountrySelector
            v-model="user.country"
            required
            name="country_isocode"
            label="Country"
          />
          <v-switch
            v-model="user.account_enabled"
            label="Account enabled"
          ></v-switch>
          <v-switch
            v-model="user.account_locked"
            label="Account locked"
          ></v-switch>
          <v-text-field
            disabled
            :value="new Date(user.signup_date).toLocaleString()"
            name="signup_date"
            label="Sign-up date"
          />
          <v-text-field
            disabled
            :value="new Date(user.signoff_date).toLocaleString()"
            name="signoff_date"
            label="Sign-off date"
          />
          <v-text-field
            disabled
            v-if="user.digital_id"
            :value="user.digital_id_country + '/' + user.digital_id"
            name="digital_id_full"
            label="Digital ID"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            class="mx-auto"
            :disabled="!valid"
            :loading="saving"
          >
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import CountrySelector from "./CountrySelector";

export default {
  name: "UserForm",
  components: {
    CountrySelector
  },
  props: {
    user: { type: Object, required: true },
    saving: { type: Boolean, default: false }
  },
  data() {
    return {
      valid: true,
      success: true,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "Must be a valid e-mail address"
      ],
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length >= 2) || "Name must be longer than 2 characters"
      ]
    };
  },
  methods: {
    async submit() {
      // TODO: publish user
      if (this.valid) {
        this.$emit("submit", this.user);
      }
    }
  }
};
</script>
<style scoped></style>
