<template>
  <v-row wrap>
    <v-col cols="2">
      <small>{{ pack.signatures }} / {{ pack.initial_signatures }}</small>
    </v-col>
    <v-col cols="4">
      <v-slider
        small
        v-model="pack.signatures"
        readonly
        :max="pack.initial_signatures"
        track-color="grey"
        :color="
          expired
            ? 'grey'
            : pack.signatures / pack.initial_signatures <= 0.1
            ? 'red'
            : pack.signatures / pack.initial_signatures <= 0.25
            ? 'orange'
            : 'green'
        "
      ></v-slider>
    </v-col>
    <v-col cols="3">
      <small :class="expired ? 'red--text' : ''">
        Expiry: {{ new Date(pack.expiry_date).toLocaleDateString() }}
      </small>
    </v-col>
    <v-col cols="3">
      <small>
        Issued: {{ new Date(pack.generated_date).toLocaleDateString() }}
      </small>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SignaturePackageCard",
  props: { pack: { type: Object, required: true } },
  computed: {
    expired() {
      let now = new Date();
      return this.pack.expiry_date && this.pack.expiry_date < now.getTime();
    }
  }
};
</script>
