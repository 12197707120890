<template>
  <v-select :items="countries" :value="value" @input="$emit('input', $event)" />
</template>

<script>
import CountryCodes from "@/services/CountryCodes";

export default {
  data() {
    return {
      countries: CountryCodes.items
    };
  },
  props: {
    value: { type: String, default: "ES" }
  },
  methods: {
    onInput($event) {
      this.$emit("input", $event);
    }
  }
};
</script>
