<template>
  <div v-if="user && !loading">
    <h3 class="text-h3 mb-8">
      User: <tt>{{ user.username }}</tt> &nbsp;<v-chip
        small
        dark
        color="gray"
        disabled
        >{{ user.id }}</v-chip
      >
    </h3>

    <v-tabs show-arrows>
      <v-tab class="justify-start">
        Overview
      </v-tab>
      <v-tab class="justify-start">
        Edit Details
      </v-tab>
      <v-tab class="justify-start">
        Edit Roles
      </v-tab>
      <v-tab class="justify-start">
        Signature Packages
        <v-chip x-small dark color="grey" class="ml-2"
          >{{ user.signature_packages.length }}
        </v-chip>
      </v-tab>

      <v-tab-item>
        <UserCard :user="user" />
      </v-tab-item>
      <v-tab-item>
        <UserForm :user="user" :saving="saving" @submit="saveUser" />
      </v-tab-item>
      <v-tab-item>
        <UserRoleForm
          :all="allRoles"
          :initial="user.roles"
          :saving="saving"
          @submit="saveRoles"
        />
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <SignaturePackageList :signatures="user.signature_packages" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <SnackBar v-model="snack.show" :text="snack.message" :color="snack.color" />
  </div>
</template>

<script>
import Backend from "@/services/Backend";
import SignaturePackageList from "@/components/SignaturePackageList";
import SnackBar from "@/components/SnackBar.vue";
import UserCard from "@/components/UserCard.vue";
import UserForm from "@/components/UserForm.vue";
import UserRoleForm from "@/components/UserRoleForm.vue";

export default {
  components: {
    SignaturePackageList,
    SnackBar,
    UserCard,
    UserForm,
    UserRoleForm
  },
  props: {
    id: { type: Number, required: true }
  },
  data() {
    return {
      user: null,
      allRoles: null,
      loading: false,
      saving: false,
      snack: {
        show: false,
        message: ""
      }
    };
  },
  mounted() {
    return this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      this.user = null;
      let response = await Backend.getUser(this.id);
      this.user = response.data;

      // extra user data
      response = await Backend.getUserAssociations(this.user);
      this.user.associations = response.data;
      response = await Backend.getUserSignatures(this.user);
      this.user.signature_packages = response.data;
      this.user.signatures = this.getSignatures(this.user);
      response = await Backend.getUserPricingPlan(this.user);
      this.user.pricing_plan = response.data;
      // other data
      response = await Backend.getRoles();
      this.allRoles = response.data;

      this.loading = false;
    },
    getSignatures(user) {
      let validSignatures = 0;
      if (user.signature_packages) {
        validSignatures = user.signature_packages
          .filter(q => q.enabled)
          .filter(q => q.expiry_date > Date.now())
          .reduce((acc, q) => acc + q.signatures, 0);
      }
      return validSignatures;
    },
    async saveUser(user) {
      this.saving = true;
      const toBeSaved = { ...user };
      delete toBeSaved.associations;
      delete toBeSaved.signature_packages;
      delete toBeSaved.signatures;
      delete toBeSaved.pricing_plan;
      try {
        await Backend.putUser(user.id, toBeSaved);
        this.saveError = null;
        this.snack = {
          show: true,
          message: `User "${this.user.username}" saved`
        };
      } catch (e) {
        // TODO: can pull up snackbar to a higher level component like App?
        this.snack = { show: true, message: e.message, color: "error" };
      }
      // console.log(response.data);
      this.saving = false;
      this.showSnackMessage = true;
    },
    async saveRoles(roles) {
      this.saving = true;
      try {
        await Backend.putUserRoles(this.user.id, roles);
        this.saveError = null;
        this.snack = {
          show: true,
          message: `User roles for "${this.user.username}" saved`
        };
      } catch (e) {
        // TODO: can pull up snackbar to a higher level component like App?
        this.snack = { show: true, message: e.message, color: "error" };
      }

      this.saving = false;
      this.showSnackMessage = true;
    }
  }
};
</script>
