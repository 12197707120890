<template>
  <div>
    <v-switch v-model="showExpired" label="Also show expired packages" />
    <v-list subheader v-if="signatures.length > 0">
      <v-list-item
        v-for="signature_package in validPackages"
        :key="signature_package.id"
      >
        <v-list-item-title class="py-2">
          <SignaturePackageCard :pack="signature_package" />
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SignaturePackageCard from "@/components/SignaturePackageCard";

export default {
  name: "SignaturePackageList",
  components: { SignaturePackageCard },
  props: { signatures: { type: Array, required: true } },
  data() {
    return { showExpired: false };
  },
  computed: {
    validPackages() {
      if (this.showExpired) {
        return this.signatures;
      } else {
        let now = new Date();
        return this.signatures.filter(p => p.expiry_date > now);
      }
    }
  }
};
</script>
