<template>
  <div>
    <v-form ref="form" @submit.prevent="submit">
      <v-card flat>
        <v-card-text>
          <v-switch
            v-for="role in selectedRoles"
            v-model="role.selected"
            :key="role.name"
            :label="role.name"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            class="mx-auto"
            :loading="saving"
          >
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "UserRoleForm",
  props: {
    all: { type: Array, required: true },
    initial: { type: Array, required: true },
    saving: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedRoles: []
    };
  },
  created() {
    const roleData = [];
    for (let role of this.all) {
      let found = this.initial.find(r => r.id == role.id) !== undefined;
      roleData.push({ name: role.name, selected: found });
    }
    this.selectedRoles = roleData;
  },
  methods: {
    async submit() {
      const newRoles = this.selectedRoles
        .filter(r => r.selected)
        .map(r => r.name);
      this.$emit("submit", newRoles);
    }
  }
};
</script>
<style scoped></style>
